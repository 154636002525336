const code = require('../code.json')

const domainMap = {
  'giteta.com': 'giteta',
  'gitarray.com': 'gitarray'
}
window.location.hostname === 'giteta.com' && console.log('giteta atob!: ', atob('giteta'))
window.location.hostname === 'gitarg.com' && console.log('gitarg atob!: ', atob('gitarg'))
window.location.hostname === 'gitarray.com' && console.log('gitarray atob!: ', atob('gitarray'))
function Code () {
  const { hostname } = window.location
  const blob = code[domainMap[hostname]]
  return (
    <pre className="code">
      { atob(blob || code.gitarg) }
    </pre>
  )
}

export default Code
