const repoMap = {
  gitlab: 'https://gitlab.com/me2211/gitarg_eth_ico/-/blob/main/',
  github: 'https://github.com/Epict33tus/solidity_ico/blob/main/'
}

const fileMap = {
  'localhost': 'gitarg.sol',
  'ico.gitarg.com': 'gitarg.sol',
  'gitarg.com': 'gitarg.sol',
  'www.gitarg.com': 'gitarg.sol',
  'giteta.com': 'giteta.sol',
  'www.giteta.com': 'giteta.sol',
  'gitarray.com': 'gitarray.sol',
  'www.gitarray.com': 'gitarray.sol'
}
function FileLink ({ repoName }) {
  const { hostname } = window.location 
  const fileName = fileMap[hostname]
  return (
    <a style={{ textAlignt: "left", color: 'black' }} href={`${repoMap[repoName]}${fileName}`}>{`${fileName} - ${repoName}`} </a>
  )
}

export default FileLink
