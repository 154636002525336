import gitetaGitlog from "../3x/giteta-gitlog@3x.png"
import gitetaCommits from "../1x/giteta-commits.png"
import crosschainRepo from "../1x/crosschain-repo.png"
import gitetaCommitBurnCycle from "../1x/giteta-commit-burn-cycle.png"

function Giteta ({ doj }) {
  return (
    <div>
      <h1>Whitepaper (giteta) 1.0.1</h1>
      <h2>Overview</h2>
      <div className="paper">
          <p className="block">
            Timelines are fundamental to the collection of real artifacts from noise. Cross chain alignment is trusted on web3 where multiple crypto currencies are respected and trusted for the storage of information and validity allows decentralization to achieve coalescence. Keys traded either in centralized or decentralized platforms create this cross chain alignment at cost of the transaction or through HTTP implicit trust and timing.
          </p>
      </div>
      <h2>Purpose</h2>
      <div className="paper">
        <p className="block">
          The purpose of decentralized ledger technology is consistent timing of events across multiple nodes connected over standard networking technology. Network event tracking and development tracking work together to provide an accurate chain of events that can replay for analysis or functionality. Transaction technology is the base of secure communication by order of event. Timestamps aren’t enough, but knowing what timing a network is communicating to the whole has implications reaching into space travel and the metaverse, preventing confusion of events and accurately defining communication and source of truth.
        </p>
      </div>
      <h2>Design</h2>
      <div className="paper">
        <p className="block">
          Storage of code and information coincide to create developer trust and accountability. A ledger system to verify logging of events in relation to the commits or micro-commits of code to verify the timeline of events in relation to the git log, or a batch processing set up for algorithms that create accurate communication. A developer interface familiar and accessible to developers creates industry adoption, making development of critical infrastructure possible in a decentralized or distributed environment. 
        </p>
      </div>
      <div className="paper">
        <p className="block">
          Commits submitted by developers coinciding with log events creates consistency for developers and their work history from multiple sources. Managers logging events across multiple sources of truth can determine changes or lock code into a direction relational to work events.
        </p>
      </div>
      <div className="paper">
        <p className="block">
          Work history and merges work together:
        </p>
      </div>
      <div className="paper">
        <img style={{ maxWidth: '100%' }} src={gitetaGitlog} />
      </div>
      <div className="paper">
        <p className="block">
          The timing of commits may be system dependent or independent from each other. An interface between block chains allows each chain to time in correlation with the coding events that occur in process to the overall code timeline or timeline of development. Algorithms stored in the giteta pattern allow users to accurately determine where events with changes can cause issues in transaction or algorithm.
        </p>
      </div>
      <div className="paper">
        <p className="block">
          Tracking the commits when they happen, and then reconciling the differences in a managed way allows accuracy timing in edits and traces of what is applied in order and relation to the system and its design. The commit trail, seen in the logs and the order of events of the commit trail creates stability of design. The design of the system is trackable in its changes, and the sections of the managed code allows for diagnosis of change with permanence.
        </p>
        </div>
        <div className="paper">
          <img src={gitetaCommits} />
        </div>
        <div className="paper">
          <p className="block">
            Branches of commits, at merge, are reconcilable but not network hashable until they are. The issue of source of truth becomes paramount as each branch’s origin is relatable to a set of commits. The merge is a codebase act, allowed to act as a commit from one branch to another. Missing or appended commits that aren’t managed, create lost changes or fuzzy changes.
          </p>
        </div>
        <div className="paper">
          <p className="block">
            These timelines, in blockchain development, create better vectorization and longer lasting stabilization of the system to create more accurate trailing, adding value to the overall security of the system and allowing users to choose code that works securely over variable code, trading on value of stability, security and useability.
          </p>
        </div>
        <div className="paper">
          <img src={crosschainRepo} />
        </div>
        <div className="paper">
          <p className="block">
            Tracking and analysis of successful code can result in higher valued repositories for smart contracts or critical infrastructure both on chain and in standard technology.
          </p>
        </div>
        <h2> Implementation </h2>
        <div className="paper">
          <p className="block">
            Creation of timestamping systems for git repositories involves recording dates and times on user devices in addition to check-in times on the blockchain. The blockchain is capable of recording what repository has at what times and what it is capable of at those times. Gapping of commits can indicate less resilience in development.
          </p>
        </div>
        <h3> The interface components: </h3>
        <div className="paper">
          <p className="block">
            <br />
            1) Committing
            <br />
	             The act of tracking commits by repo
            <br />
            2) Valuing
            <br />
	             Value of the commit based on query/timing/revision
            <br />
            3) Chunking
            <br />
               Chunks of commits by time/address/value/creator
            <br />
          </p>
        </div>
        <div className="paper">
        <div >
          <img style={{ width: "100vw" }} src={gitetaCommitBurnCycle} />
        </div>
        </div>
        <div className="paper">
          <p className="block">
            Each commit is relational to a value score. The cycle includes a voting process controlled by the repo owner that allows the controller to automate a valuing system with the specific code related to an integration, transaction or set of transactions. Each commit is valued based on the amount of time the commit is active in the code base, incentivizing removal of old code to balance production value and security.
          </p>
        </div>
        <div className="paper">
          <p className="block">
          The value of a commit depends on the instances in which the (up) function is called, not the instances in which it is used, allowing developers to pace their work based on the token value they have. They have a certain target for the tolerability of fault in the codebase depending on its value, and the code that has the most potential risk can have the highest level of return or tolerance to the system if the interface is unsecure or in need of replacement.
          </p>
        </div>
        <div className="paper">
          <p className="block">
          Each call to the (up) function burns gas or takes value to make a transaction with the burnable token. The burnable token then creates a value store based on the up function.
          </p>
        </div>
        <div>
        </div>
      <h1 style={{ height: "30px", paddingBottom: "1em" }}>{doj}</h1>
      <a href="https://gitarray.com/"> gitarray </a>
    </div>
  )
}

export default Giteta
