import diagram from '../gitarg.png'
import value_filter from '../SVG/value-filter.svg'
import network_transgression from '../SVG/network-transgression.svg'
//import Gas from './Gas'
    //{ process.env.REACT_APP_SALES === 'true' && <Gas /> }

function Gitarg ({ doj }) {

  return (
  <div>
    <div className="paper">
      <p className="block" >token purchase: inquries gitarg.eth, interface coming soon!</p>
    </div>
    <h1>Whitepaper (gitarg) 1.0.1</h1>
          <h2>Overview</h2>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <p className="block" style={{maxWidth: '400px'}}>
          Decentralization only sustains with unity of interface. Humanity can speak many languages, computers only one, tracking and maintaining this humanity while interfacing with technology can be difficult, requiring a change management system, secure resource management of computational power, and code sourced for safety of the individual, organization and environment. In the Age of AI this need is doubled, directing computational power with human centric innovation.
          </p>
          </div>
          <h2>Purpose</h2>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <p className="block" style={{maxWidth: '400px'}}>
          The creation of reusable and non-intrusive solutions supports community growth and reduces stranding and financial loss through entropy.
          A true cash system can’t suffer entropy, only inflation and deflation. Cryptocurrency suffers from decentralized entropy or the loss of vectorization.
          Support for mobility and actionability of code and information critically underpends freedom and safety of investment and communication.
          Integration of standards through code safety and security allows businesses, developers and communities to thrive through organization.
          </p>
          </div>
          {/* <h1 style={{ height: "30px", paddingBottom: "1em" }}>{dots[j]}</h1> */}
          <h2>Design</h2>
          <div className="paper">
          <p className="block">
          The purpose of standardization is tool access or accessibility. Clean design close to industry platforms with focus on readability maintains standards through generations, building prosperity for the community surrounding technology. Reliable interoperability without context to order is the basis for well timed operation in a ledger based system where transactions and communication coalesce. This forms the standard repository concept, where commits on smart contracts are the smallest increment of coalescence for communication between timed ledger interfaces.
          </p>
          </div>
          <div className="paper">
          <p className="block">
            ipfs://Qmb71Pk5uKW4tsMVZCaqbTqgC4SepRL4GF6semTjCUsnib
            https://bafybeif5vexx2x7kh3caxeq4cmpxaxw7lz6qh3bjd5hc2ki662hjhuxyua.ipfs.dweb.link/
          </p>
          </div>
          <img style={{ maxWidth: "100vw" }} src={diagram} />
          <div className="paper">
          <p className="block">
            Each chain can have a block seed not known to the other chain.
            <br />
            <br />

            Each hash is an epoch.
            <br />
            <br />
            Each wallet can contain the keys for a crypto currency. This can act as a store for value in an existing system or as a wallet in the Gitarg system with the address of the wallet with the addition of a semi-private or partial key as a seed. The block seed is a secret that the chaining algorithm solves to. The private keys are stored by the user like any other crypto currency or hidden by storage of location and a third pseudo-address to meet standards in artificial intelligence.
            <br />
            <br />
            The algorithm, similar to a standard decentralized ledger system, hashes at network rate or relational to the network propagation of a datum. The accuracy of the information in the system is a product of the ledger system’s ability to keep track of transactions in the order they happened, leading to the definition of an epoch. The block seed is known to the owner of the blockchain. A magic number for the epoch solved to an epoch reward or labeled in the diagram as a hash or hash group places the chain in the direction of accuracy, however prediction becomes the barrier.
            <br />
            <br />
            <img style={{ maxWidth: "100vw" }} src={network_transgression} />
            <br />
            <br />
            With perfect hash groups, the network speed is the barrier for prediction. The network communication is the barrier when the reward is proportional to an agreed timed release of a block reward. The amount of the reward is equal to the risk of algorithmic prediction in computational power of the amount of data publicly available to the decentralized system with indeterminate input at node interface. The most current version of the ledger is telegraphed at an interval to the stipend of reward from the nodes containing the private hashed data.
          <br />
          <br />
          <img style={{ maxWidth: "100vw" }} src={value_filter} />
          <br />
          <br />
          The ledger otherwise could be appended to assign value ownership of the address space to the wrong owner, making the staked private data valueless or assigned incorrectly. This rate could be outpaced by a node that assigns a transaction to an address that didn’t commit the transaction, allowing funds to change ownership without risk. Each node repeating the same calculation with a seed shared in a node group relational to network vectorizated potential, signing transactions at schedule to avoid computational competition that can result in inaccuracy. This means that giving access to a private key and address pair as the block reward creates a value incentive for accuracy of the ledger system.
          <br />
          <br />
          E = t(Px^n)
          <br />
          <br />
          An (E) epoch is defined as the time function of (P) prediction complexity multiplied by the (x) number of datum scaled by the (n) number of nodes.

          <br />
          <br />
          For the gitarg system we assume time is a constant function or consistent on device without using it in the calculation of datum propagation. This means we can consider it a multiplier of complexity in a given problem:

          <br />
          <br />
          E/(t) = Px^n 
          <br />
          <br />
          Without proof, we are assuming that (t) is both a function and its own parameter relying on physics and underlying computing to solve for predictive complexity of a network of (n) many nodes. This leaves us with the epoch defined in relation to time, and the complexity as a predictive function relying on the network propagation. 
          <br />
          <br />
          We remove E/(t) and replace it with 1 representing a generic singular time for one epoch:
          <br />
          <br />

          1 = Px^n
          Now we have:

          <br />
          <br />

          1/P = x^n
          Or

          <br />
          <br />

          (n)rt(1) = Px

          <br />
          <br />

          Both of which are valid, one for propagation from an information (datum) source and one to the terminals of propagation. Each side of the prediction complexity (P) defines the encryption algorithm’s complexity assuming we solve equally on all nodes. This can be a problem with infinite many nodes, solvable by larger and larger node sets with slower handshakes or:

          <br />
          <br />
          Ns = [Px1, Px2, Px3, …] -> Px1[x^n(1)(1), x^n(1)(2)...]
          <br />
          <br />
          The larger epoch sets require more complexity.
          </p>
          <div style={{ transform: "rotate(0.25turn)" }}>scroll</div>
          </div>
          <h2>Implementation</h2>
          <div className="paper">
          <p className="block">
          The vision for a secure future of web3 involves code safety, and verifiability of changes of code on the decentralized ledger system. Smart contracts separate blockchain from fully decentralized ledger systems that operate automated from singular choice, producing value for the community beyond traditional cash systems. The product focus is in the name!
          </p>
          <div className="paper">
          <p className="block">
            Each blockchain system has its merit and purpose. Wallets, native tokenization, and smart contracts order integration magnitude:

            <br />
            <br />

            Level 1:
              <br />
              Wallet
            <br />
            <br />
            Level 2:
            <br />
	            Native Token
            <br />
            <br />
            Level 3:
            <br />
	            Smart Contract
            <br />
            <br />
            Level 4:
            <br />
	            Network Integrated Hashed Smart Contract
            <br />
            <br />
          </p>
          <div style={{ transform: "rotate(0.25turn)" }}>scroll</div>
          </div>
          </div>
          <div className="paper">
            <p className="block" style={{ height: "300px" }}>
              <table>
                <thead>
                  <tr><th>Ledger Systems</th></tr>
                  <tr><th>Name</th><th>Level</th><th>Features</th></tr>
                </thead>
                <tbody>
                  <tr><td>Bitcoin</td><td>1</td><td>Wallet</td></tr>
                  <tr><td>Dogecoin</td><td>1</td><td>Wallet</td></tr>
                  <tr><td>Ripple</td><td>2</td><td>Wallet, Tokens</td></tr>
                  <tr><td>Ethereum</td><td>3</td><td>Wallet, Tokens, Smart Contracts</td></tr>
                  <tr><td>Cardano</td><td>3</td><td>Wallet, Tokens, Smart Contracts</td></tr>
                  <tr><td>Uniswap</td><td>4</td><td>Wallet, Tokens, Smart Contracts, Swap Protocol</td></tr>
                  <tr><td>Gitarg</td><td>4</td><td>Wallet, Tokens, Smart Contracts, Swap Protocol, Network(s) Integrated Hashed Smart Contracts, Tracked Changeable Smart Git</td></tr>
                </tbody>
              </table>
            </p>
          </div>
          <div className="paper">
            <p className="block">
              * Note on Uniswap: contracts in solidity and based on ethereum platform – not cross chain
            </p>
          </div>
            <h3>GITARG Token</h3>
            <div className="paper">
              <p className="block">
                A release of 1000000000000000 micro tokens. These tokens are used with the product to manage commits, perform targetted changes, provide a value store on code, and provide value to users for code changes. Additionally the trade of repositiries to different identity groups, placing intersecting value on code in relation to the token implementation and stable coins accessability.
              </p>
              <p className="block">
                0xb3Bdb3e25BB580CA98480a5fD7c98E6516750685
              </p>
            </div>
          <a href="https://giteta.com">giteta</a>
       </div>
       )
}

export default Gitarg
