import { useEffect, useState } from 'react'
import FileLink from './components/FileLink.js'
import Gitarg from './components/Gitarg.js'
import Giteta from './components/Giteta.js'
import Gitarray from './components/Gitarray.js'
import Code from './components/Code.js'
import logo from './logo.svg'
import diagram from './gitarg.png'
//crosschain connectorRepoproblem codechange vector.........commit
import giteta_commit_check_1 from './SVG/giteta-commit-check-1.svg'
import './App.css'
const { gitarg } = require('./code.json')
console.log({ gitarg })
//import { gitarg } from './code.json'

const gitRepos = {
  gitlab: 'https://gitlab.com/me2211/gitarg_eth_ico/-/blob/main/gitarg.sol',
  github: 'https://github.com/Epict33tus/solidity_ico/blob/main/gitarg.sol'
}
let dots = ['', '', '.', '..', '...']
let j = 0
let f = 0
setInterval(() => {
  if (j === dots.length - 1) j = 0
  j += 1
}, 1122)

function App() {
  const [gitargSolUrl, setGitargSolUrl] = useState('gitlab')
  const [doj, setDoj] = useState('')
  let i = 0
  let flag = false
  useEffect(() => {
    setTimeout(() => {
      if (doj.length === 3) {
        flag = true
      }
      if (flag) {
        setDoj('')
        flag = false
        return
      }
      setDoj(doj + '.')
    }, 1000)
  }, [doj])
  useEffect(() => {
    setInterval(() => {
      i++
      if (i > 4) {
        if (i > 7) {
          i = 0
        }
        return
      }
      if (Math.random() > .4) {
        setGitargSolUrl('gitlab')
      } else {
        setGitargSolUrl('github')
      }
    }, 2243 * Math.random())

    /* wow
    setInterval(() => {
      if (f.length === 3) setDoj('')
      f += 1
      setDoj(doj + '.')
    }, 2000)
    */
  }, [])
  //console.log({ f, doj })
  //const icoDomain = window.location.hostname === 'ico.gitarg.com'
  const gitargCom = window.location.hostname === 'gitarg.com'
  return (
    <div className="App">
      <header className="App-header">
        { process.env.REACT_APP_GAMES && <div className="motto">No alternate realities, so we can play games!</div> }
        <div style={{ color: 'white' }}>3*3 - 1 = 8 -> ∞</div>
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        <br />
        <a
          className="App-link"
          href={!gitargCom ? "https://gitarg.com" : "https://docs.google.com/document/d/1-ldLCEmYJBTsyL5KnsJHDjytl9EOlAziKUBXX__3nLo/edit?usp=sharing"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {!gitargCom ? 'whitepaper' : 'ICO Google Doc'}
        </a>
        <div className="code-container">
          <div>
          {/*<a style={{ textAlignt: "left", color: 'black' }} href={gitRepos[gitargSolUrl]}>gitarg.sol - {gitargSolUrl}</a>*/}
          <FileLink repoName={gitargSolUrl} />
          <Code />
          { /*<pre className="code">
          { atob(gitarg) }
          </pre>*/}
          </div>
          <div style={{ marginTop: '1em' }}>
            <div style={{ color: 'black', transform: "rotate(0.25turn)" }}>scroll</div>
          </div>
        </div>
      </header>
      { (window.location.hostname === 'gitarg.com') && <Gitarg doj={doj} /> }
      { (window.location.hostname === 'giteta.com' || window.location.hostname === 'localhost') && <Giteta doj={doj} /> }
      { (window.location.hostname === 'gitarray.com') && <Gitarray doj={doj} /> }
      { (window.location.hostname === 'gitorg.org') && <img src={giteta_commit_check_1} /> }
      <a href="https://x.com/davidjkamer" style={{ fontSize: '4px' }}> djk </a>
    </div>
  )
}

export default App
