import gitarrayHandshakeWallet from "../1x/gitarray-wallet-handshake.png"
import gitarrayHandshakeWallet100 from "../1x/gitarray-wallet-handshake-100.png"

function Gitarray ({ doj }) {
  return (
    <div>
    <h1> Whitepaper (gitarray) 0.9.0 </h1>
    <h2> Overview </h2>
    <div className="paper">
      <div className="block">
        Programmers need an easy interface to work with distributed or trusted code. Git is used by nearly every coder on the planet in addition to video editors and creatives. Creating an interface for git as an intrusion detection system and optimization system is critical to the safety of future developers, in the age of artificial intelligence, code safety and memory are critical to stability. Repositories act as an organization tool for interfaces and design goals. It’s important that code is managed by the group that relies on it in an organized way. Human intervention to reduce the potential value loss of increasingly complex systems coincide with the work done by ledger integration developers.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Identity, user management, account ownership and information integrity provide(s) the backbone for trusted communication on the internet. The ownership of wallet addresses can be verifiable and provide a method of secure handshake and recovery. The ownership verifiability can often result in a single point of failure; alleviating this with implementation of multi-signature security in the form of address handshakes and handshake adjustability results in secure ownership with verifiability, reducing potential losses.
      </div>
    </div>
    <h2> Purpose </h2>
    <div className="paper">
      <div className="block">
      Decentralized systems need an interface that’s agreeable to the user. Users of ledger technology run the system at every level, and agreement is critical to the process of maintaining equity for the user. Computational costs are shared by the users. Paying into a decentralized system balanced by the community in two ways: token and rate. The rate of the currency and an interface to burn or reallocate the token to fund the interface leads to a long term project and a trusted product. The product relies on repositories or an interface to manage developer communication, and product development is faced with organizational requirements in line with the value of the interface.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Creating interfaces that manage repository health and reward developers automatically can create manageable products that last in the long term and create, store value without losing memory of the ledger’s integrity. The ledger’s interfaces require extra scrutiny and management not previously required in traditional non-transaction based systems. The ledger system’s integrity is directly correlated to the value of the system, and collapsing that integrity into its own system allows developers to maintain forward development in a way that’s safe and ensures value retention through mechanisms to reduce loss.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Ownership is critical to the success of a project, proving authority in transaction or in accountability of its success in a positive way or by way of notoriety in lieu of liability. The recognition of someone’s contribution and their public statements and image coalesce to trusted effort towards design goals or implementation of a design. Recognizing a pattern of noted contributions and platform usage allows the public or consumer to actively pursue original and trusted (untampered) interface for information and transaction.
      </div>
    </div>
    <h2>Design</h2>
    <div className="paper">
      <div className="block">
        Each repository can accumulate tokens based on its value to the system. Each repository has a set of commits grouped into branches, however each commit is the measure of value in the gitarg system, and each repository is the value store for the gitarray system. The gitarray system stores linked repositories to allow commits to total in value for both the creator and the replacer based on either repo owner or a democratized system, ideally dependent on threat vectorization or operability. The value of the system is recognized by the configuration of the giteta vectorization and replacement process.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Wallets or addresses of validity create the backbone of trust in any ledger system. Decentralized ledger systems create trust with validity of the wallet’s privacy or asymmetric encryption of the wallet and its content to the ledger system and information on the ledger system that validates the ownership of information on the decentralized system. Compromise of the wallet in a system entirely reliant on that wallet, compromises the integrity of the entire system with a single point of failure. Traditional security relies on a three layer architecture, however allowing for multi-mode signature or a revolving door of addresses with private keys to secure true identity at a threshold of tolerance allows for the product to retain verifiability in a distributed manner without the issuance of multiple identity. 
      </div>
    </div>
    <div className="paper">
      <img src={gitarrayHandshakeWallet} /> 
    </div>
    <div className="paper">
      <div className="block">
        Multiple private signatories allows for multiple signatures to convey action on the contract or for the information owned by the identity. The success threshold of required handshakes may be variable, however standard security models generally follow the rule of reduced surface. This generally means majority or minus-one if full one for one isn’t required. As an example, the threshold could be set to 100%, however recoverability would suffer.
      </div>
    </div>
    <div className="paper">
      <img src={gitarrayHandshakeWallet100} />
    </div>
    <div className="paper">
      <div className="block">
        This threshold can be adjusted on the same security model, using the same value or a different value to adjust, but will use the same value in the gitarray system. 
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Handshake addresses can be added to the pool through a total system or by a threshold value. This allows a mineable system to adapt and change to prevent loss of private keys through entropy or attacks on validity. At the hashrate of competition, the keys or wallets can change to outpace the miners, retaining validity of communication.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        Traditional security models of our time allow for three layers of security or access–admin, moderator, user as example or read, write, execute. This means in a system not intended for mining, the processes should resemble three layers, or 2 handshakes–owner or 3 handshakes to add a handshake. In a system intended for mining, the hashrate controller can be a mode set based on the threshold of either the majority directly or a tempering algorithm.
      </div>
    </div>
    <div className="paper">
      <div className="block">
        The tampering algorithm could be controlled by access to the wallet or a transaction from the wallet signing the request for a new wallet associated with the handshake. The product of the removal or the mining group accessing the wallet on chain could act as the controller over explicit voting with the wallet(s).
      </div>
    </div>
    <h1 style={{ height: "30px", paddingBottom: "1em" }}>{doj}</h1>
    </div>
  )
}

export default Gitarray
